import React from 'react'
import SEO from '../components/seo'
import { useTranslations } from '../hooks/use-translations'

const PublisherPrivacyPolicyPage: React.FC = () => {
  const { t } = useTranslations()
  return (
    <>
      <SEO title="Publisher Privacy Policy" />
      <section className="section">
        <div className="container">
          <h2 className="subtitle is-3">{t('Signal')} for Revit</h2>
          <h2 className="subtitle is-3">{t('Signal')} for Navis </h2>
          <div className="content is-medium">
            <h1 className="title">Publisher Privacy Policy</h1>
            <ol type="1">
              <li>Our app don't collect any data.</li>
              <li>
                Our app don't transmit any data to third parties companies.
              </li>
              <li>Our app don't retention any data.</li>
            </ol>
          </div>
        </div>
      </section>
    </>
  )
}

export default PublisherPrivacyPolicyPage
